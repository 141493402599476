import React from "react";
import {
  useNewsIndexData,
  useAllArticlesData,
  useAllPressReleasesData,
  useAllPressKitsData,
} from "@staticQueries";
import { Section, Container, Text, RichText, Image } from "@base";
import {
  Hero,
  ArticleCardLarge,
  LatestNewsIndex,
  MediaKits,
  Contact,
} from "@organisms";
import { DefaultLayout } from "@layout";
import { AnimateIn, Paginator } from "@ui";
import { getTailwindColor } from "@utils";
import { useAppState } from "@state/appState";
import SliceFullBleedImage from "../components/molecules/SliceFullBleedImage";

const NewsIndex = pageProps => {
  const [{ pagination }, dispatch] = useAppState();
  const { hero, news, media, contact, meta } = useNewsIndexData();

  return (
    <DefaultLayout {...pageProps} meta={meta}>
      {hero.heading && (
        <SliceFullBleedImage
          {...hero}
          textY="bottom"
          textX="center"
          size="news"
        />
      )}
      <LatestNewsIndex {...news} />
      <MediaKits {...media} />
      <Contact {...contact} />
    </DefaultLayout>
  );
};

export default NewsIndex;
